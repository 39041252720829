
// Default .button class
.button {
    @extend %button;
    display: inline-block;
    padding: 0.7rem 1.2rem;
    border-radius: $border-radius;
    transition: $transition;
    font-size: $font-size-body - .1rem;
    font-weight: $font-semi-bold;

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }
}
