
@mixin columns-three($order: right)
{

    @if $order != left
    {
        .column {

            &.left {
                display: block;
                // set the column to use flexbox
                // we can now switch the order of the sub-nav between mobile/desktop
                display: flex;
                flex-direction: column;
                width: 100%;

                @media #{$mobileXL} {
                    clear: both;
                }

                @media #{$tabletXL} {
                    clear: none;
                    float: left;
                    width: $columns-three-left;
                    margin-left: -100%;
                }
            }

            &.right {
                display: block;

                @media #{$mobileXL} {
                    float: right;
                    width: $columns-three-right-small;
                }

                @media #{$tabletXL} {
                    width: $columns-three-right;
                    margin-right: -($columns-three-right);
                    left: -($columns-three-right);
                    padding-top: 22rem;
                }
            }

            &.centre {
                display: block;

                @media #{$mobileXL} {
                    float: left;
                    width: calc(100% - (#{$columns-three-right-small} + #{$gutter-three-right-small}));
                }

                @media #{$tabletXL} {
                    margin-right: calc(#{$columns-three-right} + #{$gutter-three-right});
                    margin-left: calc(#{$columns-three-left} + #{$gutter-three-left});
                    width: calc(100% - (#{$columns-three-left} + #{$gutter-three-left}) - (#{$columns-three-right} + #{$gutter-three-right}));
                }
            }
        }
    }
    @else
    {
        .main {
            display: flex;
            flex-flow: row wrap;
        }

        .column {

            &.left {
                order: 3;
                display: block;
                // set the column to use flexbox
                // we can now switch the order of the sub-nav between mobile/desktop
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 0;

                @media #{$tabletXL} {
                    order: 1;
                    width: $columns-three-left;
                    margin-left: -100%;
                }
            }

            &.centre {
                order: 1;
                width: 100%;
                margin: 0;

                @media #{$mobileXL} {
                    order: 1;
                    margin-right: $gutter-three-right-small;
                    margin-left: 0;
                    width: calc(100% - (#{$columns-three-right-small} + #{$gutter-three-right-small}));
                }

                @media #{$tabletXL} {
                    order: 2;
                    margin-right: $gutter-three-right;
                    margin-left: $gutter-three-left;
                    width: calc(100% - (#{$columns-three-left} + #{$gutter-three-left}) - (#{$columns-three-right} + #{$gutter-three-right}));
                }
            }

            &.right {
                order: 2;
                display: block;
                left: 0;
                width: 100%;
                margin: 0;

                @media #{$mobileXL} {
                    width: $columns-three-right-small;
                }

                @media #{$tabletXL} {
                    order: 3;
                    left: 0;
                    width: $columns-three-right;
                    margin: 0;
                }
            }
        }
    }
}

#contact {
    .column {

        &.right {
            padding: 0;
        }
    }
}
