
@mixin columns-two-left($show: none)
{

    .column {

        @if $show != all
        {
            &.right {
                display: none;
            }
        }

        &.left {
            display: block;
            // set the column to use flexbox
            // we can now switch the order of the sub-nav between mobile/desktop
            display: flex;
            flex-direction: column;
            width: 100%;
            clear: none;
            margin: 0;

            @media #{$tablet} {
                float: left;
                width: $columns-two-left-small;
            }

            @media #{$tabletXL} {
                width: $columns-two-left;
            }
        }

        &.centre {
            display: block;
            width: 100%;
            margin: 0;

            @media #{$tablet} {
                float: right;
                width: calc(100% - (#{$columns-two-left-small} + #{$gutter-two-left-small}));
            }

            @media #{$tabletXL} {
                width: calc(100% - (#{$columns-two-left} + #{$gutter-two-left}));
            }
        }
    }

    @if $show == all {
        .main {
            display: flex;
            flex-flow: row wrap;
        }

        .column {

            &.left {
                order: 3;
                display: block;
                // set the column to use flexbox
                // we can now switch the order of the sub-nav between mobile/desktop
                display: flex;
                flex-direction: column;

                @media #{$mobileXL} {
                    order: 1;
                }
            }

            &.centre {
                order: 1;

                @media #{$mobileXL} {
                    order: 2;
                    margin-left: $gutter-two-left-small;
                }

                @media #{$tabletXL} {
                    margin-left: $gutter-two-left;
                }
            }

            &.right {
                order: 2;
                display: block;
                left: 0;
                width: 100%;
                margin: 0;

                @media #{$mobileXL} {
                    order: 3;
                }
            }
        }
    }
}

// Remove padding-top on specific pages
#contact {

    .column {

        &.left {
            padding-top: 0;
        }
    }
}
