
// Content styles
.centre .content {

	// Default paragraphs
	p {
		margin: 0 0 ($paragraph-margin - .5);

		&.intro {
			padding-bottom: ($paragraph-margin - .5);
			border-bottom: $border;
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $font-size-body + .2;

			@media #{$mobileXL} {
				font-size: $font-size-body + .1;
			}
		}
	}

	// New default <ul> lists
	* + ul {
		@extend %default-ul;
	}

	// New default <ol> lists
	* + ol {
		@extend %default-ol;
	}
}

